<template>
  <div class="noDemand">
    <div class="videoBox">
      <div id="player"></div>
    </div>
    <div class="content">
      <div class="left">
        <div class="leftHead">
          <div class="leftHeadLe">
            <div class="leftHeadLeTitle">{{detailWhole.title}}</div>
            <div class="leftHeadLeNum">
              <span>学习人数：{{detailWhole.study_count}}人</span>
              <span style="margin-left: 24px;">{{detailWhole.score}}分</span>
            </div>
          </div>
          <div class="leftHeadRit">
            <p @click="shareClick"><img src="@/assets/img/play/6076.png"
                   alt=""><span>分享</span></p>
            <p @click="dialogVisible = true"><img src="@/assets/img/play/607.png"
                   alt=""><span>评价</span></p>
          </div>
        </div>
        <!-- tab 切换 -->
        <div class="leftLower">
          <div style="">
            <div class="leftLowerTab">
              <div class="tabStyle"
                   :class="{tabStyleBorder:tabIndex==ie.id}"
                   v-for="(ie,ia) in tabContent"
                   :key="ia"
                   @click="tabClick(ie)">
                <div>{{ie.name}}</div>
              </div>
            </div>
            <!-- 课程介绍 -->
            <div class="htmlWordStyle"
                 v-if="tabIndex==1">
              <curriculum :wordHtml="content"></curriculum>
            </div>
            <!-- 讲师介绍 -->
            <div v-if="tabIndex==2">
              <lecturer :wordhtml='lecturerContent'></lecturer>
            </div>
            <!-- 课程目录 -->
            <div v-if="tabIndex==3">
              <catalog-curriculum :whole="curriculumList"
                                  :index="catalogIndex"
                                  @on-change="changeVideo"></catalog-curriculum>
            </div>
            <!-- 评论 -->
            <div v-if="tabIndex==4">
              <details-comment :wholeComment="comment"
                               :id='id'
                               @comment-change='commentReturn'></details-comment>
            </div>
          </div>
          <!-- 评论input框 -->
          <div class="commentInp"
               v-if="tabIndex==4">
            <p>发表评论</p>
            <div class="commentInpPro">
              <div class="commentInpProBorder">
                <img src="@/assets/img/play/editicon.png"
                     alt="">
                <input type="text"
                       v-model="commentContent"
                       placeholder="在此写下您的看法">
              </div>
              <div class="commentInpProBut"
                   @click="commentClick">发表评论</div>
            </div>
          </div>
          <!-- 轮播图 -->
          <div style="height:91px;width:90%;padding:30px 43px;"
               v-if="imgPro.image_pc != null"
               @click="goother({path: '/play/richText',query:{id:id}})">
            <img style="height:100%;width:100%;"
                 :src="imgPro.image_pc">
          </div>
        </div>
      </div>
      <!-- 右侧 推荐内容 -->
      <div class="right">
        <div style="background: #fff;padding: 27px 27px 10px;">
          <div class="rightHead">
            <img src="@/assets/img/play/247.png"
                 alt="" />
            <span>热门课程</span>
          </div>
          <!-- 推荐课程 -->
          <div class="rightDetail"
               v-for="(it,is) in hotList.res"
               :key="is"
               :class="{curr:is%2 == 0}"
               @click="goother({path:'/play',query:{id:it.id}})">
            <!-- 有图换这个 -->
            <img :src="it.face_img"
                 alt=""
                 class="rightDetailimg">
            <div class="rightDetailTitle">
              {{it.title}}
            </div>
            <div class="rightDetailTime">
              {{it.duration}}
            </div>
            <div style="display: flex;align-items: center;">
              <div class="rightDetailName"
                   v-for="(ic,iz) in it.lecturer_info"
                   :key="iz">
                <img :src="ic.portrait"
                     alt="">
                <div>{{ellipsText(ic.real_name)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="课程评分"
               :visible.sync="dialogVisible"
               width="20%">
      <div class="newsConent">
        <div class="newsConentExb"><span>课程先进性</span>
          <el-rate v-model="value1"
                   :disabled="scoreBoolean"></el-rate>
        </div>
        <div class="newsConentExb"><span>课程实用性</span>
          <el-rate v-model="value2"
                   :disabled="scoreBoolean"></el-rate>
        </div>
        <div class="newsConentExb"><span>讲师表现力</span>
          <el-rate v-model="value3"
                   :disabled="scoreBoolean"></el-rate>
        </div>
      </div>
      <div :class="detailWhole.course_score_info == 0?'newsBut0':'newsBut1'"
           @click="evaluateClick">{{detailWhole.course_score_info == 0?'评价':'您已提交评价'}}</div>
    </el-dialog>
    <el-dialog :visible.sync="share"
               width="35%">
      <div style="display: flex;justify-content: center;align-items: center;">
        <div class="shareLeft">
          <img :src="poster"
               alt="">
        </div>
        <div class="shareRight">
          <div class="shareRightWord">
            <p>请下载邀请卡分享给您的好友</p>
            <p>每成功邀请1位好友完成手机号注册</p>
            <p>将获得20积分奖励</p>
          </div>
          <div class="shareRightBut"
               @click="download">
            下载邀请卡
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import curriculum from '@/components/curriculum'
import lecturer from '@/components/lecturer'
import catalogCurriculum from '@/components/catalogCurriculum'
import detailsComment from '@/components/detailsComment'
import { course_info, hot_course, add_comment, add_look_log, study_point, add_course_score, course_dealImage, scerweima } from '@/utils/Api/play'
export default {
  name: "noDemand",
  components: { curriculum, lecturer, catalogCurriculum, detailsComment },
  data () {
    return {
      id: null,//课程id
      tabType: 1,//直播还是点播
      tabIndex: 1,//tab切换下标
      tabContent: [],//tab切换展示
      hotList: [],//热门课程
      // 直播
      tab: [{
        id: 1,
        name: '课程介绍',
        page: 1
      }, {
        id: 2,
        name: '讲师介绍',
        page: 1
      }],
      // 点播
      tabPlay: [{
        id: 1,
        name: '课程介绍',
        page: 1
      }, {
        id: 2,
        name: '讲师介绍',
        page: 1
      }, {
        id: 3,
        name: '课程目录',
        page: 1
      }, {
        id: 4,
        name: '评论',
        page: 1
      }],
      // 不想写了
      tabPlayPro: [{
        id: 1,
        name: '课程介绍',
        page: 1
      }, {
        id: 2,
        name: '讲师介绍',
        page: 1
      }, {
        id: 4,
        name: '评论',
        page: 1
      }],
      content: "",//富文本内容
      lecturerContent: "",//讲师富文本内容
      imgPro: [],//轮播图
      curriculumList: [],//课程目录
      comment: [],//评论
      commentContent: null,//评论内容
      vodPlayerJs: 'https://player.polyv.net/script/player.js',
      vid: '',//视频路劲
      videoId: null,//视频id
      player: null,//保利威播放器
      detailWhole: { plan_time: {} },//全部订单详情
      commentWhether: '',//评论返回信息
      indexVid: null,//课程详情页点击课程目录返回的vid
      catalogIndex: null,//选重下标
      timer_inter: null,// 页面停留计时器
      remain_time: 0,//页面停留时间
      remain_timePro: 0,//用来关闭送积分接口
      see_long: 0,//上次视频观看时间
      time_long_Pro: null,//视频总时间长度 秒
      time_long: null,//视频总时间长度 分钟
      realPlayStatus: 0,//获取到的当前视频观看时间长度 不加原来观看时间
      realPlayVideoTime: 0,//获取到的当前视频观看时间长度 加上原来看的时间的
      addLookLogWord: null,//生成观看记录接口返回
      looked: null,//上次观看时间
      dialogVisible: false,//评价提示框
      value1: 5,//评分1
      value2: 5,//评分2
      value3: 5,//评分3
      numberValue: null,//评分接口返回
      scoreBoolean: false,//评分的布尔值判断
      share: false,//分享
      poster: null,//海报接口返回内容
      qrCode: null,//二维码
      count_point: 0, //已获取积分次数
      point_times: 0, //可获取积分次数
    }
  },
  // 相当于onload
  created () {
    this.vid = this.$router.currentRoute.params.vid
    this.id = this.$router.currentRoute.params.id
    this.videoId = this.$router.currentRoute.params.videoId
    this.catalogIndex = this.$router.currentRoute.params.index
    this.details()
    this.hotCurriculum()
    this.recordTime()
    if (this.$store.getters.personal.id != undefined) {
      this.scerweimaPro()
    }
  },
  // 页面挂载后执行方法
  mounted () {
    this.loadPlayerScript(this.loadPlayer);
  },
  // 页面写在声明周期 检测退出页面 退出之后 卸载播放视频框
  destroyed () {
    // if(this.looked == this.time_long_Pro){
    this.watchTime();
    // }
    // this.realPlayStatus = JSON.parse(this.player.j2s_realPlayStatus()).playduration
    // let lookedPro = parseFloat(this.realPlayStatus) + parseFloat(this.looked)
    // this.realPlayVideoTime = ( lookedPro / 60).toFixed(2)
    // this.see_long = (this.realPlayStatus / 60).toFixed(2)
    // this.remain_time = (parseFloat(this.remain_time / 60)).toFixed(2)
    // this.addLookLog()
    clearInterval(this.timer_inter)
    this.timer_inter = null
    if (this.player) {
      this.player.destroy();
    }
  },
  methods: {
    async details () {
      this.detailWhole = await course_info({ course_id: this.id })
      this.content = this.detailWhole.introduce
      this.lecturerContent = this.detailWhole.lecturer_introduce
      this.imgPro = this.detailWhole.advert_info
      this.curriculumList = this.detailWhole.catalogue
      this.comment = this.detailWhole.comment
      this.detailWhole.type == 1 ? this.tabContent = this.tab : this.curriculumList.length == 1 ? this.tabContent = this.tabPlayPro : this.tabContent = this.tabPlay
      if (this.curriculumList[this.catalogIndex].looked == 0) {
        this.looked = this.curriculumList[this.catalogIndex].looked
      } else {
        this.looked = Math.ceil((parseFloat(this.curriculumList[this.catalogIndex].looked * 60)))
      }
      this.remain_time = (parseFloat(this.curriculumList[this.catalogIndex].remain_time * 60)).toFixed(1)
      this.point_times = this.curriculumList[this.catalogIndex].point_times ? this.curriculumList[this.catalogIndex].point_times : 0
      this.count_point = this.curriculumList[this.catalogIndex].count_point ? this.curriculumList[this.catalogIndex].count_point : 0
      this.see_long = (parseFloat(this.curriculumList[this.catalogIndex].see_long * 60)).toFixed(1)
      if (this.detailWhole.course_score_info != 0) {
        this.value1 = this.detailWhole.course_score_info.advanced
        this.value2 = this.detailWhole.course_score_info.utility
        this.value3 = this.detailWhole.course_score_info.expressive
        this.detailWhole.course_score_info != 0 ? this.scoreBoolean = true : scoreBoolean = false
      }
    },
    // 热门课程
    async hotCurriculum () {
      this.hotList = await hot_course({ type: 1, page: 1, limit: 2 })
      // console.log('热门课程',this.hotList)
    },
    // 生成学习课程接口
    async addLookLog () {
      var last_time = this.timeCountdown(this.player.j2s_getCurrentTime())
      this.addLookLogWord = await add_look_log({ course_id: this.id, video_id: this.videoId, see_long: this.see_long, looked: this.realPlayVideoTime, last_time: last_time, time_long: this.time_long, remain_time: this.remain_time, })
      this.details()
    },
    timeCountdown (e) {
      const hh = parseInt(e / 60 / 60)
      e = e - 60 * 60 * hh
      const mm = parseInt(e / 60)
      e = e - 60 * mm
      const ss = parseInt(e)
      return (hh ? hh + ':' : '') + (mm < 10 ? ('0' + mm) : mm) + ':' + (ss < 10 ? ('0' + ss) : ss)
    },
    // 赠送学习积分
    async studyPoint () {
      let ai = await study_point({ course_id: this.id, video_id: this.videoId })
      if (ai != undefined) {
        this.$message.success(`获取积分成功`)
      }
    },
    // 检测有没有 script 标签 引入 保利威 js 链接 没有就创建一个 在body标签下边
    loadPlayerScript (callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script');
        myScript.setAttribute('src', this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    // 创建一个 保利威 播放视频框 用户查看 播放
    loadPlayer () {
      const me = this
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: '#player',
        width: 1200,
        height: 675,
        vid: this.vid,
        ban_history_time: false,
      });
      // 播放器初始化完毕时触发
      this.player.on('s2j_onPlayerInitOver', (e) => {
        this.time_long_Pro = this.player.j2s_getDuration()
        if (this.looked > this.time_long_Pro) {
          this.looked = this.time_long_Pro
        }
        this.time_long = (parseFloat(this.time_long_Pro / 60)).toFixed(2)
        // console.log('sjaj', this.time_long_Pro, this.time_long)
      })
      // 视频初次播放时触发
      this.player.on('s2j_onPlayStart', (e) => {
        this.player.j2s_seekVideo(this.looked);
      });

      // 当前视频播放完毕时触发，参数返回vid
      this.player.on('s2j_onPlayOver', (e) => {
        // console.log('视频播放完毕', e)
        clearInterval(this.timer_inter)
        this.timer_inter = null
        this.watchTime();
      })
    },
    tabClick (e) {
      this.tabIndex = e.id
    },
    // 评论接口
    commentClick () {
      this.addComment()
    },
    // 评论接口
    async addComment () {
      if (this.commentContent != null) {
        this.commentWhether = await add_comment({ course_id: this.id, content: this.commentContent })
        if (this.commentWhether.length == 0) {
          this.commentContent = null
          this.details()
          this.$message.success(`评论成功`)
        }
      } else {
        this.$message.error(`请先填写内容`)
      }
    },
    // 计算点播时间
    watchTime () {
      // console.log('计算点播时间', this.looked, this.time_long_Pro)
      if (this.looked != this.time_long_Pro) {
        const timePro = parseFloat(JSON.parse(this.player.j2s_realPlayStatus()).playduration)
        if (timePro > this.time_long_Pro) {
          this.realPlayStatus = this.time_long_Pro
        } else {
          this.realPlayStatus = timePro
        }
        // console.log('看了多少秒', this.realPlayStatus)
        let lookedPro = parseFloat(this.realPlayStatus) + parseFloat(this.looked)
        this.realPlayVideoTime = (lookedPro / 60).toFixed(2)
        this.see_long = (this.realPlayStatus / 60).toFixed(2)
        this.remain_time = (parseFloat(this.remain_time / 60)).toFixed(2)
        // console.log('烦', this.realPlayStatus, lookedPro, this.realPlayVideoTime, this.see_long, this.remain_time)
        this.addLookLog()
      }
      // this.remain_time = 0

    },
    // 记录时间
    recordTime () {
      this.timer_inter = setInterval(() => {
        this.remain_time++
        // console.log(this.point_times, this.count_point, this.point_times > this.count_point)
        if (this.point_times > this.count_point && this.remain_timePro == 0) {
          let a = this.player.j2s_getCurrentTime()
          // console.log(this.remain_time / this.time_long_Pro >= 0.8, parseFloat(a) / this.time_long_Pro >= 0.8)
          if (this.remain_time / this.time_long_Pro >= 0.8 && parseFloat(a) / this.time_long_Pro >= 0.8) {
            this.studyPoint();
            this.count_point++
            this.remain_timePro = 1
            this.$store.dispatch('user/getUserInfo');
          }
        }
        // if (this.remain_timePro == 0) {
        //   if (this.remain_time / this.time_long_Pro >= 0.8 && parseFloat(JSON.parse(this.player.j2s_realPlayStatus()).playduration) / this.time_long_Pro >= 0.8) {
        //     this.remain_timePro = 1
        //     this.studyPoint();
        //     this.$store.dispatch('user/getUserInfo');
        //   }
        // }
      }, 1000)
    },
    // 点击课程目录播放视频 返回内容
    changeVideo (vid) {
      if (vid.vidPro != this.vid) {
        // console.log('切换视频',vid)
        this.watchTime();
        this.vid = vid.vidPro
        this.remain_timePro = 0
        this.remain_time = 0
        this.player.changeVid({ vid: this.vid });
        this.catalogIndex = vid.ind
        this.videoId = vid.video
        this.remain_time = vid.remain_time
        this.see_long = vid.see_long
        this.looked = vid.looked
        this.point_times = vid.point_times ? vid.point_times : 0
        this.count_point = vid.count_point ? vid.count_point : 0
        console.log('切换', this.looked, this.remain_time, this.see_long)

      }
    },
    // 评论个人信息返回
    commentReturn (e) {
      // console.log('评论个人信息返回',e)
      this.details()
    },
    // 截取文字 点点点
    ellipsText (text, num = 4) {
      if (text) {
        if (text.length > num) {
          return text.substring(0, num) + '...';
        } else {
          return text;
        }
      } else {
        return text
      }
    },
    async scerweimaPro () {
      let url = `/pages/players/video_brief?course_id=${this.id}`
      let b = {
        url: url,
        thoken: this.$store.getters.token,
        invite_code: this.$store.getters.personal.code,
        organ_id: this.$store.getters.personal.id,
      }
      console.log('ssj', b)
      this.qrCode = await scerweima(b)
      this.courseDealImage()
    },
    async courseDealImage () {
      let a = {
        course_id: this.id,//	是	string	课程id
        code_url: this.qrCode,//	是	string	二维码地址
        code_x: 210,//	是	string	二维码x坐标 大概 800
        code_y: 395,//	是	string	二维码y坐标 大概1600
        code_w: 80,//	是	string	二维码宽 大概100
        code_h: 80,//	是	string	二维码高 大概100
        bill_y: 120,//	是	string	海报y坐标 大概30
        bill_w: 300,//	是	string	海报宽度 大概1200
        bill_h: 10,//	是	string	海报高度 大概300
        head_x: 18,//	是	string	头像x坐标 大概200
        head_y: 305,//	是	string	头像y坐标 大概1400
        head_w: 80,//	是	string	头像宽 大概300
        head_h: 80,//	是	string	头像高 大概300
        name_x: 85,//	是	string	姓名x坐标 大概200
        name_y: 330,//	是	string	姓名y坐标 大概1400
        name_size: 12,//	是	string	姓名文字大小 大概40
        title_x_diff: -25,//	是	string	课程名偏移量 大概150
        title_y: 70,//	是	string	课程名y坐标 大概1000
        title_size: 16,//	是	string	课程名像素 大概40
        title_w: 200,//	是	string	课程名宽度 大概700
      }
      this.poster = await course_dealImage(a)
      this.la = this.poster.split('qrcode/')[1]
      console.log('海报图', this.poster)
    },
    // 下载
    download () {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL(this.poster)
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = 'photo'
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = this.poster
      console.log('图片', image)
    },
    //分享按钮
    shareClick () {
      if (this.$store.getters.personal.id == undefined) {
        this.$message.error(`请先登录账号`);
        return;
      }
      this.share = true
    },
    // 评分
    evaluateClick () {
      if (this.detailWhole.course_score_info == 0) {
        this.addCourseScore();
      }
    },
    // 评分接口
    async addCourseScore () {
      this.numberValue = await add_course_score({ course_id: this.id, advanced: this.value1, utility: this.value2, expressive: this.value3 })
      if (this.numberValue.length == 0) {
        this.$message.success(`评分成功`)
        setTimeout(() => {
          this.dialogVisible = true
        }, 2000)
      }

    },
  },

};
</script>

<style scoped>
.videoBox {
  width: 100%;
  height: 783px;
  background: #131314;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play {
  width: 100%;
  background: #fafafa;
}
.content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.left {
  width: 953px;
  margin-right: 19px;
}
.leftHead {
  width: 100%;
  height: 70px;
  text-align: initial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.leftHeadLe {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.leftHeadLeTitle {
  font-size: 17px;
  color: #333333;
}
.leftHeadLeNum {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #999999;
}
.leftHeadRit {
  display: flex;
}
.leftHeadRit img {
  width: 19px;
  height: 19px;
}
.leftHeadRit p {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leftHeadRit p:nth-child(1) {
  margin-right: 37px;
}
.leftHeadRit span {
  font-size: 14px;
  color: #999999;
}

/* 课程介绍 */
.leftLower {
  /* height: 1640px; */
  margin: 16px 0 30px;
  background: #fff;
}
.leftLowerTab {
  display: flex;
  text-align: initial;
  padding: 19px 31px;
}

.tabStyle {
  padding: 9px 0;
  font-size: 16px;
  color: #333;
  margin-right: 39px;
  cursor: pointer;
}

.tabStyleBorder {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  border-bottom: 4px solid #51d5dd;
  margin-right: 39px;
  cursor: pointer;
}
/* 富文本样式 */
.htmlWordStyle {
  /* widows: 706px; */
  /* height: 1381px; */
  margin: 0 auto;
  /* text-align: initial ; */
  /* overflow-y: auto; */
}
/* 右边 */
/* 右边 */
.right {
  width: 336px;
  /* background: #fff; */
  /* padding: 0 27px; */
}
.rightHead {
  color: #333333;
  font-size: 24px;
  display: flex;
}
.rightHead img {
  width: 23px;
  height: 31px;
  margin-right: 17px;
}
.rightDetail {
  padding-bottom: 20px;
  cursor: pointer;
}
.rightDetailimg {
  height: 140px;
  margin: 27px 0 20px;
  background: #47d7e3;
}
.rightDetailTitle {
  text-align: initial;
}
.rightDetailTime {
  text-align: initial;
  margin: 13px 0;
}
.rightDetailName {
  text-align: initial;
  display: flex;
  margin-right: 5px;
}
.rightDetailName img {
  width: 26px;
  height: 26px;
  /* background: #47d7e3; */
  border-radius: 100px;
  overflow: hidden;
  margin-right: 9px;
}
.curr {
  border-bottom: 1px solid #dddddd;
}
/* 发表评论 */
.commentInp {
  margin-top: 19px;
  padding: 17px 31px;
}
.commentInp p {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  text-align: initial;
  margin-bottom: 5px;
}
.commentInpPro {
  display: flex;
  height: 52px;
  padding: 0 62px;
}
.commentInpProBorder {
  display: flex;
  align-items: center;
  border: 1px solid #bdb7b7;
  width: 83%;
  padding: 0 13px;
}
.commentInpProBorder img {
  width: 18px;
  height: 20px;
}
.commentInpProBorder input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  margin-left: 18px;
  color: #909090;
}
.commentInpProBut {
  width: 86px;
  height: 100%;
  background: #47d7e3;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* 评价 */
.newsConent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newsConentExb {
  display: flex;
  font-size: 15px;
  color: #666666;
  margin-bottom: 10px;
}
.newsConentExb span {
  margin-right: 37px;
}
.newsBut0 {
  width: 192px;
  height: 32px;
  margin: 0 auto;
  background: #47d7e3;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.newsBut1 {
  width: 192px;
  height: 32px;
  margin: 0 auto;
  background: #dedede;
  border-radius: 3px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
</style>
